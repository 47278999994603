<template>
	<div>
		<transition :duration="250" enter-active-class="animated fadeIn" leave-active-class="animated fadeOut">
			<div v-show="CutImgPopup" class="Popup"></div>
		</transition>
		<transition :duration="250" enter-active-class="animated fadeInDown" leave-active-class="animated fadeOutUp">
			<div v-show="CutImgPopup" class="CutImg_box">
				<div class="Popup_c_top">
					<h3>裁剪</h3>
					<button @click="close_btn" class="Pop_btn">
						<i class="el-message-box__close el-icon-close"></i>
					</button>
				</div>
				<div class="info-item" style="flex:1; margin-top:5px;">
					<div class="line">
						<div class="cropper-content">
							<div :class="cropper">
								<VueCropper ref="cropper" :img="option.img" :outputSize="option.size" :outputType="option.outputType" :info="true" :full="option.full" :canMove="option.canMove" :centerBox="option.centerBox" :canMoveBox="option.canMoveBox" :original="option.original" :autoCrop="option.autoCrop" :autoCropWidth="option.autoCropWidth" :autoCropHeight="option.autoCropHeight" :fixedBox="option.fixedBox" @realTime="realTime" @imgLoad="imgLoad"></VueCropper>
							</div>
							<div style="margin-left:20px;">
								<div class="show-preview" :style="{'width': '150px', 'height':'155px',  'overflow': 'hidden', 'margin': '5px'}">
									<div :style="previews.div" class="preview">
										<img :src="previews.url" :style="previews.img">
									</div>
								</div>
							</div>
						</div>
					</div>
					<input type="file" id="uploads" :value="imgFile" style="position:absolute; clip:rect(0 0 0 0);" accept="image/png, image/jpeg, image/gif, image/jpg" @change="uploadImg($event, 1)">
				</div>
				<div class="el-message-box__btns" style=" display: table; margin: 0 auto; margin-top: 40px; box-sizing: border-box; position: relative; top: 25px;">
					<label for="uploads" type="button" class="el-button el-button--default el-button--small el-button--primary ">
						<span>重新选择</span>
					</label>
					<button @click="cutBtn" type="button" class="el-button el-button--default el-button--small el-button--primary ">
						<span>确定</span>
					</button>
				</div>
			</div>
		</transition>
	</div>
</template>

<script>
	/* eslint-disable */
	import Vue from 'vue'
	import VueCropper from 'vue-cropper'
	Vue.use(VueCropper)

	export default {
		data() {
			return {
				headImg: '',
				//剪切图片上传
				crap: false,
				previews: {},
				option: {
					img: '',
					outputSize: 1, //剪切后的图片质量（0.1-1）
					full: false, //输出原图比例截图 props名full
					outputType: 'png',
					canMove: true,
					original: false,
					canMoveBox: true,
					autoCrop: true,
					autoCropWidth: this.cutWidth,
					autoCropHeight: this.cutHeight,
					fixedBox: true,
					centerBox:true
				},
				fileName: '', //本机文件地址
				downImg: '#',
				imgFile: '',
				CutImgPopup: false,
				cropper:this.cutType==1?'cropper cropper2':'cropper'
			}
		},
		// components: {
		// 	'VueCropper':VueCropper
		// },
		props:['cutWidth','cutHeight','cutType'],//cutType:0方形，1圆形，如果是圆形，高度=宽度
		methods:{
			// 实时预览函数 
			realTime(data) {
				this.previews = data
			},
			//选择本地图片
			uploadImg(e, num) {
				var _this = this;
				//上传图片 
				var file = e.target.files[0]
				_this.fileName = file.name;
				if(!/\.(gif|jpg|jpeg|png|bmp|GIF|JPG|PNG)$/.test(e.target.value)) {
					alert('图片类型必须是.gif,jpeg,jpg,png,bmp中的一种')
					return false
				}
				var reader = new FileReader();
				reader.onload = (e) => {
					let data;
					if(typeof e.target.result === 'object') {
						// 把Array Buffer转化为blob 如果是base64不需要 
						data = window.URL.createObjectURL(new Blob([e.target.result]))
					} else {
						data = e.target.result
					}
					if(num === 1) {
						_this.option.img = data
					} else if(num === 2) {
						_this.example2.img = data
					}
				}
				// 转化为base64 
				// reader.readAsDataURL(file) 
				// 转化为blob 
				reader.readAsArrayBuffer(file);
			},
			imgLoad(msg) {
			},
			close_btn() {
				this.CutImgPopup = false;
			},
			cutBtn() {
				var that=this;
				// 获取cropper的截图的base64 数据
				this.$refs.cropper.getCropData(data => {
					this.$parent.cutImg=data;
					setTimeout(function(){
						that.$parent.uploadCutImg()
						that.CutImgPopup=false;
					},30)
				})
			}
		},
		created(){
			if(this.cutType==1){
				this.cutHeight=this.cutWidth
			}
		}
	}
</script>

<style lang="less">
	.info {
		width: 720px;
		margin: 0 auto;
		.oper-dv {
			height: 20px;
			text-align: right;
			margin-right: 100px;
			a {
				font-weight: 500;
				&:last-child {
					margin-left: 30px;
				}
			}
		}
		.info-item {
			margin-top: 15px;
			label {
				display: inline-block;
				width: 100px;
				text-align: right;
			}
			.sel-img-dv {
				position: relative;
				.sel-file {
					position: absolute;
					width: 90px;
					height: 30px;
					opacity: 0;
					cursor: pointer;
					z-index: 2;
				}
				.sel-btn {
					position: absolute;
					cursor: pointer;
					z-index: 1;
				}
			}
		}
	}
	
	.cropper-content {
		display: flex;
		display: -webkit-flex;
		justify-content: flex-end;
		-webkit-justify-content: flex-end;
		.cropper {
			width: 260px;
			height: 260px;
		}
		.show-preview {
			flex: 1;
			-webkit-flex: 1;
			display: flex;
			display: -webkit-flex;
			justify-content: center;
			-webkit-justify-content: center;
			.preview {
				overflow: hidden;
				border-radius: 50%;
				border: 1px solid #cccccc;
				background: #cccccc;
				margin-left: 40px;
			}
		}
	}
	
	.cropper-content .show-preview .preview {
		margin-left: 0;
	}
	.CutImg_box .el-button--primary{background-color: #736357; border-color: #736357;}
</style>